.ddvZdT,.bOmZtP div:first-child {
    white-space: break-spaces !important;
}

.datatable {
    width: 76vw;
    overflow: hidden;
}

.hZZlND {
    overflow-x: scroll !important;
}